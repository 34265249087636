<template>
  <div class="logo"><img :src="logo" /></div>
</template>

<script>
import config from '~/config';

export default {
  name: 'BLogo',
  props: {
    logoId: {
      type: Number,
      required: false,
      default: () => 0,
    },
    isFlipPlus: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  computed: {
    logo() {
      const vm = this;

      if (!vm.logoId) {
        return vm.isFlipPlus ? `assets/flip-plus-160.png` : `assets/flip-nondr-logo.png`;
      }
      return `${config.ADREADY_URL}/api/files/download/${vm.logoId}?download=false`;
    },
  },
};
</script>

<style lang="scss" scoped>
.logo img {
  width: auto;
  height: 60px;
}
</style>
